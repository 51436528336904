<template>
  <div class="errorbox">
    <div class="error-con">
      <div class="error-txt-primary">ERROR</div>
      <div class="error-txt-des">SYSTEM ERROR</div>
      <div class="error-txt-small">LOOKS LIKE SOMETHING IS WRONG</div>
      <div class="error-txt-big">WE ARE WORKING ON IT</div>
      <a @click="jumpTo('/home')" class="back-btn">BACK TO HOME</a>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    jumpTo(menu) {
      this.current = menu;
      this.$router.push(menu);
    },
  },
};
</script>

<style lang="less" scoped>
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.errorbox {
  width: 100vw;
  height: 100vh;
  background-image: url("~@/static/images/errorbg.jpeg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  // position: relative;
}
.error-con {
  position: absolute;
  left: 20%;
  top: 50%;
  margin-top: -220px;
}
.error-txt-primary {
  color: #4f76b0;
  font-size: 120px;
  font-weight: bold;
}
.error-txt-des {
  color: #255cac;
  font-size: 42px;
  font-weight: bold;
}
.error-txt-small {
  color: #678bc0;
  font-size: 20px;
  margin-top: 20px;
}
.error-txt-big {
  color: #678bc0;
  font-size: 26px;
}
.back-btn {
  color: #fff;
  background: #ff9147;
  width: 190px;
  height: 56px;
  line-height: 56px;
  text-align: center;
  display: block;
  border-radius: 28px;
  text-decoration: none;
  margin-top: 20px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
</style>